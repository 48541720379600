// assets
import { DashboardOutlined,StockOutlined,ClockCircleOutlined,PlusSquareOutlined,OrderedListOutlined,ShoppingCartOutlined,DesktopOutlined } from '@ant-design/icons';

// icons
const icons = {
  DashboardOutlined,
  StockOutlined,
  ClockCircleOutlined,
  PlusSquareOutlined,
  OrderedListOutlined,
  ShoppingCartOutlined,
  DesktopOutlined
};

const client = window.sessionStorage.getItem("client")
const level = window.sessionStorage.getItem("level")
// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const PurchaseOrder = {
  id: 'group-dashboard',
  title: 'Purchase Orders',
  type: (client === 'alamode' || client === 'demo' || client === 'rubans') ? 'group' : 'none',
  children: [
    {
      id: 'podashboard',
      title: 'Dashboard',
      type: (client === 'alamode' || client === 'rubans') ? 'item' : 'none',
      url: '/home/Purchase/Orders/Dashboard',
      icon: icons.DashboardOutlined,
      breadcrumbs: true
    },
    {
      id: 'podashboarddemo',
      title: 'Purchase Order Tracking',
      type: client === 'demo' ? 'item' : 'none',
      url: '/home/Purchase/Orders/Dashboard/Demo',
      icon: icons.DesktopOutlined,
      breadcrumbs: true
    },
    {
      id: 'pocart',
      title: 'Cart',
      type: ((client === 'alamode' || client === 'rubans') && level === 'ADMIN') ? 'item' : 'none',
      url: '/home/Purchase/Orders/Cart',
      icon: icons.ShoppingCartOutlined,
      breadcrumbs: true
    },
    {
      id: 'pocartdemo',
      title: 'Cart',
      type: (client === 'demo' && level === 'ADMIN') ? 'item' : 'none',
      url: '/home/Purchase/Orders/Cart/Demo',
      icon: icons.ShoppingCartOutlined,
      breadcrumbs: true
    }
  ]
};

export default PurchaseOrder;