import { createSlice } from "@reduxjs/toolkit";
import {
  API_LIMIT,
  DATA_FETCH_MSG,
  GET_LOCATION_DETAILS,
  SKU_PERFORMANCE_API,
} from "pages/common/Constants";
import { requestAPI, getClientId } from "pages/common/Utils";
import { populateColumns } from "store/common/populateColumns";

const initialState = {
  tableData: [],
  columnData: [],
  locationData: [],
  selectedLocation: "",
  pastdaysSales: '30',
  aggregationModel: {},
  filterModel: [],
  loading: false,
  isLoading: true,
  error: null,
  openAlert: false,
  alertMessage: "",
  severity: "success",
  btn: true,
  isTableDataFetched: false,
};

const skuPerformanceAllSlice = createSlice({
  name: "skuPerformanceAll",
  initialState,
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload;
    },

    setTableData(state, action) {
      state.tableData = action.payload;
      state.loading = false;
    },

    setLocationData(state, action) {
      state.locationData = action.payload;
    },

    setSelectedLocation(state, action) {
      state.selectedLocation = action.payload;
    },

    setPastdaysSales(state, action) {
      state.pastdaysSales = action.payload;
    },

    setAggregationModel(state, action) {
      state.aggregationModel = action.payload;
    },
    setFilterModel(state, action) {
      state.filterModel = action.payload;
    },

    setError(state, action) {
      state.error = action.payload;
      state.loading = false;
    },

    clearSkuPerformanceData(state) {
      state.tableData = [];
      state.locationData = [];
    },

    setColumnData(state, action) {
      state.columnData = action.payload;
    },
    setOpenAlert(state, action) {
      state.openAlert = action.payload.open;
      state.alertMessage = action.payload.message;
      state.severity = action.payload.severity;
    },

    setBtn(state, action) {
      state.btn = action.payload;
    },

    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setIsTableDataFetched(state, action) {
      state.isTableDataFetched = action.payload;
    },
  },
});

export const {
  loading, setLoading,
  tableData, setTableData,
  locationData, setLocationData,
  selectedLocation, setSelectedLocation,
  pastdaysSales, setPastdaysSales,
  setAggregationModel,
  setError,
  clearSkuPerformanceData,
  setColumnData,
  setOpenAlert,
  setBtn,
  setIsLoading,
  setIsTableDataFetched,isTableDataFetched
} = skuPerformanceAllSlice.actions;

export default skuPerformanceAllSlice.reducer;


export const fetchLocationDetails = (locationDataAll) => async (dispatch, getState) => {
  try {
    // Get the existing locationDataActive from the state
    const locationValue = locationDataAll.find((x) => x.attribute === "All")?.attribute || locationDataAll[0]?.attribute ;
    dispatch(setLocationData([...locationDataAll]));
    dispatch(setSelectedLocation(locationValue));

  } catch (error) {
    dispatch(setError("Failed to fetch location details"));
  }
};


export const fetchSkuPerformanceData = (days, locationValue, req) => async (dispatch) => {
  dispatch(setBtn(false));
  dispatch(setIsLoading(true));
  dispatch(setLoading(true));
  try {
    if (locationValue === ""||locationValue===undefined) { locationValue = selectedLocation}
    const response1 = await requestAPI(SKU_PERFORMANCE_API + "/" + getClientId() + "?picd=" + days + "&loc=" + locationValue + `&limit=${API_LIMIT}&req=` + req, "GET", "", "")
    dispatch(setTableData(response1.data));
    const { columns: c1, aggregation: agg1 } = populateColumns(response1.data);
    dispatch(setColumnData(c1));
    dispatch(setAggregationModel(agg1));
    dispatch(setIsLoading(false));
    dispatch(setLoading(false));

    const response2 = await requestAPI(SKU_PERFORMANCE_API + "/" + getClientId() + "?picd=" + days + "&loc=" + locationValue + "&req=" + req, "GET", "", "")
    dispatch(setTableData(response2.data));
    const { columns: c2, aggregation: agg2 } = populateColumns(response2.data);
    dispatch(setColumnData(c2));
    dispatch(setAggregationModel(agg2));
    dispatch(setBtn(true));
    dispatch(setLoading(false));
    dispatch(setIsLoading(false));
    dispatch(setOpenAlert({
      open: true,
      message: DATA_FETCH_MSG,
      severity: "success"
    }));
    dispatch(setIsTableDataFetched(true));
  } catch (error) {
    console.log("Failed to fetch SKU performance daily All: ", error);
    dispatch(setError("Failed to fetch SKU performance All data"));
  }
};








