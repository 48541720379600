// assets
import { LoginOutlined, ProfileOutlined, PlusOutlined, FormOutlined, MonitorOutlined } from '@ant-design/icons';

// icons
const icons = {
  LoginOutlined,
  ProfileOutlined,
  PlusOutlined,
  FormOutlined,
  MonitorOutlined
};

const client = window.sessionStorage.getItem("client")

// ==============================|| MENU ITEMS - EXTRA PAGES ||============================== //

const jobwork = {
  id: 'jobwork',
  title: 'Job Work',
  type: client === 'knyamed' ? 'group' : 'none',
  children: [
    {
      id: 'createjobwork',
      title: 'Create Job Work',
      type: 'item',
      url: '/home/ipo',
      icon: icons.PlusOutlined,
      breadcrumbs: true
    },
    {
      id: 'updatejobwork',
      title: 'Update Job Work',
      type: 'item',
      url: '/home/igrn',
      icon: icons.FormOutlined,
      breadcrumbs: true
    },
    {
      id: 'trackjobwork',
      title: 'Track Job Work',
      type: 'item',
      url: '/home/idash',
      icon: icons.ProfileOutlined,
      breadcrumbs: true
    },
    {
      id: 'editjobwork',
      title: 'Edit Job Work',
      type: 'item',
      url: '/home/EditJobWorkDetails',
      icon: icons.ProfileOutlined,
      breadcrumbs: true
    }
  ]
};

export default jobwork;