// assets
import {
  AppstoreAddOutlined,
  AntDesignOutlined,
  BarcodeOutlined,
  BgColorsOutlined,
  FontSizeOutlined,
  LoadingOutlined,
  FallOutlined,
  CheckOutlined
} from '@ant-design/icons';

// icons
const icons = {
  FontSizeOutlined,
  BgColorsOutlined,
  BarcodeOutlined,
  AntDesignOutlined,
  LoadingOutlined,
  AppstoreAddOutlined,
  FallOutlined,
  CheckOutlined
};
const client = window.sessionStorage.getItem("client")

// ==============================|| MENU ITEMS - UTILITIES ||============================== //

const utilities = {
  id: 'utilities',
  title: 'Utilities',
  type: client === 'knyamed' ? 'group' : 'none',
  children: [
    {
      id: 'vendorperformance',
      title: 'Vendor Performance',
      type: client === 'knyamed' ? 'item' : 'none',
      url: '/home/vp',
      icon: icons.FontSizeOutlined,
      breadcrumbs: true
    },
    {
      id: 'futureinventorylevel',
      title: 'Future Inventory Level',
      type: client === 'mirggiolife' || client === 'demo' ? 'none' : 'none',
      url: '/home/fil',
      icon: icons.BgColorsOutlined,
      breadcrumbs: true
    },
    {
      id: 'skumaster',
      title: 'SKU Master',
      type: client === 'knyamed' ? 'item' : 'none',
      url: '/home/skim',
      icon: icons.BarcodeOutlined,
      breadcrumbs: true
    },
    {
      id: 'stockoutloss',
      title: 'Stock Out Loss',
      type: client === 'knyamed' ? 'item' : 'none',
      url: '/home/oppo',
      icon: icons.FallOutlined,
      breadcrumbs: true
    }
  ]
};

export default utilities;
