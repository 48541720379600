import React, { useRef, useEffect, useState } from "react";
import { ClickAwayListener } from "@mui/material";
import {
  QuestionCircleOutlined,
  DownOutlined,
  RightOutlined,
  MinusOutlined,
  PlusOutlined,
} from "@ant-design/icons";

import "../../../../assets/styles/HelpAndSupport.css";
import { HELP_MENUS } from "pages/common/Constants";
import { requestAPI } from "pages/common/Utils";
import { useDispatch, useSelector } from "react-redux";
import {
  setDropdownStates,
  setOpen,
  setData,
} from "store/reducers/HelpSection/HelpSection";

export default function HelpAndSupport() {
  const anchorRef = useRef(null);
  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const open = useSelector((state) => state.HelpSection.open);
  const dropdownStates = useSelector((state) => state.HelpSection.dropdownStates);
  const dispatch = useDispatch();

  useEffect(() => {
    const getData = async () => {
      if (data.length === 0) {
        const resp = await requestAPI(HELP_MENUS, "GET", "", "");
        setData(resp.data);
      }
    };
    getData();
  }, []);

  const handleToggle = () => {
    dispatch(setOpen(!open));
  };

  const handleClose = () => {
    dispatch(setOpen(false));
    dispatch(setDropdownStates({}));
  };

  const handleSelectDropdown = (key) => {
    const currentDropdown = dropdownStates[key] || {};
    dispatch(
      setDropdownStates({
        ...dropdownStates,
        [key]: {
          ...currentDropdown,
          open: !currentDropdown.open,
          selectedChildren: currentDropdown.selectedChildren || [],
        },
      })
    );
  };

  const handleSelectChild = (dropdownKey, childKey) => {
    const dropdown = dropdownStates[dropdownKey] || {};
    const selectedChildren = dropdown.selectedChildren || [];
    const isSelected = selectedChildren.includes(childKey);

    dispatch(
      setDropdownStates({
        ...dropdownStates,
        [dropdownKey]: {
          ...dropdown,
          selectedChildren: isSelected
            ? selectedChildren.filter((key) => key !== childKey)
            : [...selectedChildren, childKey],
        },
      })
    );
  };

  const highlightText = (text, searchTerm) => {
    if (!searchTerm) return text;

    const regex = new RegExp(`(${searchTerm})`, "gi");
    const parts = text.split(regex);
    return parts.map((part, index) => {
      if (regex.test(part)) {
        return (
          <span key={index} style={{ backgroundColor: 'yellow', color: 'black', fontWeight: 'bold' }}>
            {part}
          </span>
        );
      }
      return part;
    });
  }
  const filteredData = data
    .map((menu) => {
      const filteredSubMenus = menu.subMenus.filter((subMenu) =>
        subMenu.content.some(
          (contentItem) =>
            (contentItem.contentName &&
              contentItem.contentName.toLowerCase().includes(searchTerm.toLowerCase())) ||
            (contentItem.value &&
              contentItem.value.toLowerCase().includes(searchTerm.toLowerCase()))
        )
      );

      if (filteredSubMenus.length > 0) {
        return {
          ...menu,
          subMenus: filteredSubMenus,
        };
      }

      return null;
    })
    .filter((menu) => menu !== null);

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div className="help-and-support">
        <div ref={anchorRef} className="help-button" onClick={handleToggle}>
          <QuestionCircleOutlined className="icon-size" />
          <span>Help</span>
        </div>

        {open && (
          <div className="popup">
            <div className="search-bar">
              <input
                type="text"
                placeholder="Search help topics..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="search-input"
              />
            </div>

            {filteredData.length === 0 && (
              <div className="no-results" style={{ padding: '4px', marginLeft: '10px' }}>No results found</div>
            )}
            {filteredData.map((menu, key) => (
              <div key={menu.menuId}>
                <div
                  className={`dropdown-header ${
                    dropdownStates[key]?.open ? "active" : ""
                  }`}
                  onClick={() => handleSelectDropdown(key)}
                >
                  {dropdownStates[key]?.open ? (
                    <DownOutlined className="dropdown-icon" />
                  ) : (
                    <RightOutlined className="dropdown-icon" />
                  )}
                  {highlightText(menu.menuName, searchTerm)}
                </div>

                {dropdownStates[key]?.open && (
                  <div className="dropdown-menu">
                    {menu.subMenus.map((subMenu) => (
                      <div key={subMenu.subMenuId}>
                        <div
                          className={`dropdown-item ${
                            dropdownStates[key]?.selectedChildren?.includes(
                              subMenu.subMenuId
                            )
                              ? "active"
                              : ""
                          }`}
                          onClick={() => handleSelectChild(key, subMenu.subMenuId)}
                        >
                          <span>{highlightText(subMenu.subMenuName, searchTerm)}</span>
                          {dropdownStates[key]?.selectedChildren?.includes(
                            subMenu.subMenuId
                          ) ? (
                            <MinusOutlined className="dropdown-icon" />
                          ) : (
                            <PlusOutlined className="dropdown-icon" />
                          )}
                        </div>

                        {dropdownStates[key]?.selectedChildren?.includes(
                          subMenu.subMenuId
                        ) && (
                          <div className="dropdown-details">
                            {subMenu.content &&
                              subMenu.content.map((childSubMenu) => (
                                <div key={childSubMenu.contentId}>
                                  <h4>
                                    {highlightText(
                                      childSubMenu.contentName || "",
                                      searchTerm
                                    )}
                                  </h4>
                                  {childSubMenu.type === "IMAGE" && (
                                    <img
                                      src={childSubMenu.value}
                                      alt={childSubMenu.contentName}
                                      style={{
                                        maxWidth: "100%",
                                        height: "140px",
                                        objectFit: "contain",
                                      }}
                                    />
                                  )}
                                  {childSubMenu.type === "TEXT" && (
                                    <div className="dropdown-answer">
                                      {highlightText(
                                        childSubMenu.value || "",
                                        searchTerm
                                      )}
                                    </div>
                                  )}
                                  {childSubMenu.type === "VIDEO" && (
                                    <div className="dropdown-answer">
                                      <video width="260" height="160" controls>
                                        <source
                                          src={childSubMenu.value}
                                          type="video/mp4"
                                          style={{
                                            maxWidth: "100%",
                                            height: "160px",
                                            objectFit: "contain",
                                          }}
                                        />
                                        Your browser does not support the video tag.
                                      </video>
                                    </div>
                                  )}
                                </div>
                              ))}
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    </ClickAwayListener>
  );
}
