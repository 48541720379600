// assets
import { ChromeOutlined, QuestionOutlined,ScissorOutlined,ProfileOutlined } from '@ant-design/icons';

// icons
const icons = {
  ChromeOutlined,
  QuestionOutlined,
  ScissorOutlined,
  ProfileOutlined
};
const client = window.sessionStorage.getItem("client")

// ==============================|| MENU ITEMS - SAMPLE PAGE & DOCUMENTATION ||============================== //

const trims = {
  id: 'trims',
  title: 'Trims',
  type: client === 'knyamed' ? 'group' : 'none',
  children: [
    {
      id: 'trimspo',
      title: 'Trims PO',
      type: 'item',
      url: '/home/tcr',
      icon: icons.ScissorOutlined
    },
    {
      id: 'trimsgrn',
      title: 'Trims GRN',
      type: 'item',
      url: '/home/tup',
      icon: icons.ScissorOutlined,
      //external: true,
      //target: true
    },
    {
      id: 'tracktrims',
      title: 'Track Trims',
      type: 'item',
      url: '/home/ttr',
      icon: icons.ProfileOutlined,
      //external: true,
      //target: true
    }
  ]
};

export default trims;
