// assets
import {
    AppstoreAddOutlined,
    AntDesignOutlined,
    BarcodeOutlined,
    BgColorsOutlined,
    FontSizeOutlined,
    LoadingOutlined,
    FallOutlined,
    CheckOutlined
  } from '@ant-design/icons';
  
  // icons
  const icons = {
    FontSizeOutlined,
    BgColorsOutlined,
    BarcodeOutlined,
    AntDesignOutlined,
    LoadingOutlined,
    AppstoreAddOutlined,
    FallOutlined,
    CheckOutlined
  };
  const client = window.sessionStorage.getItem("client")
  
  // ==============================|| MENU ITEMS - UTILITIES ||============================== //
  
  const Fabric = {
    id: 'fabric',
    title: 'Fabric',
    type: 'group',
    children: [
      {
        id: 'fabricpoform',
        title: 'Fabric PO',
        type: 'item',
        url: '/home/fabric',
        icon: icons.FontSizeOutlined,
        breadcrumbs: true
      },
      {
        id: 'fabricdashboard',
        title: 'Dashboard',
        type: 'item',
        url: '/home/fabric/dashboard',
        icon: icons.BarcodeOutlined,
        breadcrumbs: true
      },
      {
        id: 'processing',
        title: 'Processing',
        type: 'item',
        url: '/home/fabric/processing',
        icon: icons.AntDesignOutlined,
        breadcrumbs: true
      },
      {
        id: 'fabricbom',
        title: 'Fabric BOM',
        type: 'item',
        url: '/home/fabric/bom',
        icon: icons.BgColorsOutlined,
        breadcrumbs: true
      },
      {
        id: 'fabricmaster',
        title: 'Fabric Master',
        type: 'item',
        url: '/home/fabric/master',
        icon: icons.CheckOutlined,
        breadcrumbs: true
      },
      {
        id: 'fabricvendormaster',
        title: 'Vendor Master',
        type: 'item',
        url: '/home/fabric/vendormaster',
        icon: icons.FontSizeOutlined,
        breadcrumbs: true
      },
      {
        id: 'processingvendor',
        title: 'ProcessingVendor',
        type: 'item',
        url: '/home/fabric/processingvendor',
        icon: icons.AppstoreAddOutlined,
        breadcrumbs: true
      },
      {
        id: 'fabricinventorydashboard',
        title: 'Inventory Dashboard',
        type: 'item',
        url: '/home/fabric/inventorydashboard',
        icon: icons.AppstoreAddOutlined,
        breadcrumbs: true
      },
      {
        id: 'fabricinventoryout',
        title: 'Inventory Out',
        type: 'item',
        url: '/home/fabric/inventoryout',
        icon: icons.FontSizeOutlined,
        breadcrumbs: true
      },
    ]
  };
  
  export default Fabric;
  