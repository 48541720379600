
// src/redux/slices/ReplenishmentPlannerCart.js
import { createSlice } from '@reduxjs/toolkit';
import { GET_LOCATION_DETAILS } from 'pages/common/Constants';
import { requestAPI, getClientId } from "pages/common/Utils";

const initialState = {
    locationDataAll: [],
    locationDataActive: [],
};

const locationData = createSlice({
    name: 'locationData',
    initialState,
    reducers: {
        setLocationDataAll(state, action) {
            state.locationDataAll = action.payload;
        },
        setLocationDataActive(state, action) {
            state.locationDataActive = action.payload;
        },
    },
});

export const {
    setLocationDataAll,
    setLocationDataActive,
} = locationData.actions;

export default locationData.reducer;

export const fetchAllLocationDetails = () => async (dispatch) => {
    try {
        const response = await requestAPI(GET_LOCATION_DETAILS + '/' + getClientId() + "?reqtype=All", 'GET', '', '')
        dispatch(setLocationDataAll(response.data));
        
    } catch (error) {
        console.log("Failed to fetch ALL location details : ", error);
    }
};
export const fetchActiveLocationDetails = () => async (dispatch) => {
    try {
        const response = await requestAPI(GET_LOCATION_DETAILS + '/' + getClientId(), 'GET', '', '')
        dispatch(setLocationDataActive(response.data));

    } catch (error) {
        console.log("Failed to fetch ACTIVE location details : ", error);
    }
};