import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  open: false,
  dropdownStates: {},
  data: [],
};

const helpAndSupportSlice = createSlice({
  name: 'helpAndSupport',
  initialState,
  reducers: {
    setOpen(state, action) {
      state.open = action.payload;
    },
    setDropdownStates(state, action) {
      state.dropdownStates = action.payload;
    },
    setData(state, action) {
      state.data = action.payload;
    },
  },
});

// Export actions
export const { open, setOpen, setDropdownStates, setData } = helpAndSupportSlice.actions;

// Export the reducer
export default helpAndSupportSlice.reducer;