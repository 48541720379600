// project import
import jobwork from './jobwork';
import dashboard from './dashboard';
import utilities from './utilities';
import trims from './trims';
import Fabric from './Fabric';
import PurchaseOrder from './PurchaseOrder';
// ==============================|| MENU ITEMS ||============================== //
const client = window.sessionStorage.getItem("client")
const level = window.sessionStorage.getItem("level")
let menuItems = {
  items: (window.sessionStorage.getItem("module") !== null && window.sessionStorage.getItem("module") === 'fabric') ? [Fabric] : [dashboard, jobwork, utilities, trims, PurchaseOrder]
};
if(client === 'knyamed' && level === 'USER'){
  menuItems = {
    items: [jobwork]
  };
}
export default menuItems;