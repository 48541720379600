// third-party
import { combineReducers } from 'redux';

// project import
import menu from './menu';
import locationData from './location/location'
import skuPerformanceAll from './skuPerformance/skuPerformanceAll';
import skuPerformanceDaily from './skuPerformance/skuPerformanceDaily';
import skuPerformanceChannel from './skuPerformance/skuPerformanceChannel';
import skuPerformanceWeekly from './skuPerformance/skuPerformanceWeekly';
import skuPerformanceMonthly from './skuPerformance/skuPerformanceMonthly';
import inventory from './inventoryHealth/inventoryHealth'
import replenishmentPlannerCart from './replenishmentPlanner/replenishmentPlannerCart'
import instockPercentage from './instockPercentage/instockPercentage'
import inventoryDoc from './instockPercentage/InventoryDoc'
import HelpSection from './HelpSection/HelpSection';



// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineReducers({
    menu,
    locationData,
    skuPerformanceAll,
    skuPerformanceChannel,
    skuPerformanceDaily,
    skuPerformanceWeekly,
    skuPerformanceMonthly,
    inventory,
    replenishmentPlannerCart,
    instockPercentage,
    inventoryDoc,
    HelpSection
});

export default reducers;
