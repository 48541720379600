import { createSlice } from "@reduxjs/toolkit";
import {
  API_LIMIT,
  DATA_FETCH_MSG,
  GET_LOCATION_DETAILS,
  GET_SKU_PERFORMANCE_DAILY_TREND_DD_DATA,
  SKU_PERFORMANCE_DAILY_TRENDS_API
} from "pages/common/Constants";
import { requestAPI, getClientId } from "pages/common/Utils";
import { populateColumns } from "store/common/populateColumns";

// Initial state for SKU performance
const initialState = {
  tableData: [],
  columnData: [],
  locationData: [],
  channelData: [],
  channel: "",
  location: "",
  aggregationModel: {},
  filterModel: [],
  gridDataCookieNameconst: 'SKU_PERFORMANCE_MONTHLY_GRID_STATE',
  openAlert: false,
  alertMessage: '',
  severity: 'success',
  btn: true,
  isLoading: true,
  loading: false,
  error: null,
  isTableDataFetched: false,
};

// Create the SKU Performance Weekly slice
const skuPerformanceDailySlice = createSlice({
  name: "skuPerformanceDaily",
  initialState,
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setTableData(state, action) {
      state.tableData = action.payload;
      state.loading = false;
    },
    setColumnData(state, action) {
      state.columnData = action.payload;
    },
    setLocationData(state, action) {
      state.locationData = action.payload;
    },
    setChannelData(state, action) {
      state.channelData = action.payload;
    },
    setChannel(state, action) {
      state.channel = action.payload;
    },
    setLocation(state, action) {
      state.location = action.payload;
    },
    setBtn(state, action) {
      state.btn = action.payload;
    },
    setAggregationModel(state, action) {
      state.aggregationModel = action.payload;
    },
    setFilterModel(state, action) {
      state.filterModel = action.payload;
      },
    setIsTableDataFetched(state, action) {
      state.isTableDataFetched = action.payload;
    },
    setGridDataCookieName(state, action) {
      state.gridDataCookieName = action.payload;
    },
    setOpenAlert(state, action) {
      state.openAlert = action.payload.open;
      state.alertMessage = action.payload.message;
      state.severity = action.payload.severity;
    },
    setError(state, action) {
      state.error = action.payload;
      state.loading = false;
    },
    clearSkuPerformanceData(state) {
      state.tableData = [];
      state.locationData = [];
      state.channelData = [];
      state.location = '';
      state.channel = '';
    },
  },
});

// Export actions and reducer
export const {
  btn, setBtn,
  error, setError,
  loading, setLoading,
  channel, setChannel,
  location, setLocation,
  isLoading, setIsLoading,
  tableData, setTableData,
  columnData, setColumnData,
  locationData, setLocationData,
  channelData, setChannelData,
  filterModel, setFilterModel,
  aggregationModel, setAggregationModel,
  gridDataCookieName, setGridDataCookieName,
  openAlert, setOpenAlert,
  clearSkuPerformanceData,
  isTableDataFetched, setIsTableDataFetched
} = skuPerformanceDailySlice.actions;

export default skuPerformanceDailySlice.reducer;

// Async action to fetch channel data
export const fetchChannelData = () => async (dispatch) => {
  try {
    const response = await requestAPI(GET_SKU_PERFORMANCE_DAILY_TREND_DD_DATA + '?cid=' + getClientId(), 'GET', '', '');

    const channelValue = response.data.find((x) => x.attribute === "All")?.attribute || response.data[0]?.attribute || "All";
    dispatch(setChannelData(response.data));
    dispatch(setChannel(channelValue));
  } catch (error) {
    console.log("Failed to fetch channel data: ", error);
    dispatch(setError("Failed to fetch channel data"));
  }
};

// Async action to fetch location details
export const fetchLocationDetails = () => async (dispatch) => {
  try {
    const response = await requestAPI(
      GET_LOCATION_DETAILS + '/' + getClientId() + "?reqtype=SKU_PERFORMANCE",
      'GET',
      '',
      ''
    );
    const locationValue = response.data.find((x) => x.attribute === "All")?.attribute || response.data[0]?.attribute || "All";
    dispatch(setLocationData(response.data));
    dispatch(setLocation(locationValue));
    return response.data;

  } catch (error) {
    dispatch(setError("Failed to fetch location details"));
  }
};

// Async action to fetch SKU performance data
export const fetchSkuPerformanceData = (channel, location) => async (dispatch) => {
  dispatch(setBtn(false));
  dispatch(setIsLoading(true));
  dispatch(setLoading(true));
  try {
    const response1 = await requestAPI(SKU_PERFORMANCE_DAILY_TRENDS_API + "/" + getClientId() + "?ch=" + channel + "&loc=" + location + "&limit=" + API_LIMIT, 'GET', '', '');
    dispatch(setTableData(response1.data));
    const { columns: c1, aggregation: agg1 } = populateColumns(response1.data);
    dispatch(setColumnData(c1));
    dispatch(setAggregationModel(agg1));
    dispatch(setIsLoading(false));
    dispatch(setLoading(false));

    const response2 = await requestAPI(SKU_PERFORMANCE_DAILY_TRENDS_API + "/" + getClientId() + "?ch=" + channel + "&loc=" + location, 'GET', '', '');
    dispatch(setTableData(response2.data));
    const { columns: c2, aggregation: agg2 } = populateColumns(response2.data);
    dispatch(setColumnData(c2));
    dispatch(setAggregationModel(agg2));
    dispatch(setBtn(true));
    dispatch(setOpenAlert({
      open: true,
      message: DATA_FETCH_MSG,
      severity: "success"
    }));
    dispatch(setLoading(false));
    dispatch(setIsLoading(false));
    dispatch(setIsTableDataFetched(true));

  } catch (error) {
    console.log("Failed to fetch SKU performance Daily data: ", error);
    dispatch(setError("Failed to fetch SKU performance Daily data"));
  }
};
