import axios from "axios";
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from '@mui/x-data-grid-premium';

import Button from '@mui/material/Button';
import { ERROR_TRACKER, LOGOUT_LINK, TOKEN_API, USER_TRACKER } from "./Constants";

export const CustomToolbar = () => {
  const pageName = window.sessionStorage.getItem("gsReqPage")
  const link = "/home/gse?req=" + pageName

  let fileName = 'Custom-erp'
  if (pageName == 'oppo') {
    fileName = "Opportunity Loss"
  } else if (pageName == 'print') {
    fileName = "Print"
  } else if (pageName == 'predictions') {
    fileName = "Inventory Planner"
  } else if (pageName == 'best') {
    fileName = "Bestsellers"
  }

  fileName = fileName + "_" + getCurrentDate()
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport
        csvOptions={{
          fileName: fileName,
        }}
      />

      <div>
        <Button variant="text" style={{ float: "right", marginRight: "10px" }}><a style={{ textDecoration: "none", color: "dodgerblue" }} rel="noopener noreferrer" href={link}><i className="fa fa-download"></i>&nbsp; Export To Google Sheets</a></Button>
      </div>
    </GridToolbarContainer>
  );
}

export const getCurrentDate = () => {
  let newDate = new Date();
  let date = newDate.getDate();
  let month = newDate.getMonth() + 1;
  var year = newDate.getFullYear();
  return year.toString().substring(2, 4) + "-" + month + "-" + date;
}

export const getYesterdayDate = () => {
  let newDate = new Date();
  newDate.setDate(newDate.getDate() - 1);
  let day = newDate.getDate();
  let month = newDate.getMonth() + 1;
  var year = newDate.getFullYear();
  if (month < 10)
    month = "0" + month

  if (day < 10)
    day = "0" + day
  return year + "-" + month + "-" + day;
}

export const getUniqueNumber = () => {
  let newDate = new Date();
  let date = newDate.getDate();
  let month = newDate.getMonth() + 1;
  var year = newDate.getFullYear();
  let sec = newDate.getSeconds();
  let millisec = newDate.getMilliseconds();
  return year.toString().substring(2, 4) + "" + month + "" + date + "" + sec + "" + millisec;
}

export const getUniqueNumberForKnyamed = () => {
  const monthNames = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN",
    "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];

  let newDate = new Date();

  let month = newDate.getMonth();
  const monthName = monthNames[month]

  let millisec = newDate.getMilliseconds();
  return monthName + "/" + millisec;
}
export const getCurrentMonthName = (offset) => {
  const date = new Date(); // Get the current date
  date.setMonth(date.getMonth() - offset); // Subtract the offset to get the desired month
  const options = { month: 'short' }; // Options to format the month as a short name
  return date.toLocaleString('default', options); // Return the month's abbreviation
}

export const getMonthName = (inputMonth) => {
  const monthNames = ["xxx", "Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  return monthNames[inputMonth]
}

export const getPrevNextMonthAndYear = (param) => {
  const arr = param.split(" ")
  const inputMonth = arr[0]
  const inputYear = arr[1]

  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  let result = []
  let idx = 1
  monthNames.map(x => {
    if (inputMonth === x) {
      if (idx % 12 === 0) {
        result.push(11)
        result.push(12)
        result.push(1)
      } else if (idx % 12 === 1) {
        result.push(12)
        result.push(1)
        result.push(2)
      } else {
        result.push(idx - 1)
        result.push(idx)
        result.push(idx + 1)
      }

      result.push(inputYear)
    }
    idx++;
  })
  return result;
}

export const getFormattedDateAndMonth = (prevDayCount) => {
  let dateObj = new Date();
  let month = dateObj.getMonth() + 1

  const monthNames = ["xxx", "JAN", "FEB", "MAR", "APR", "MAY", "JUN",
      "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];

  let day = dateObj.getDate()
  day = parseInt(day) - prevDayCount
  return day + " " + monthNames[month]
}

export const getFormattedDate = (date) => {
  let dateObj = date['$d'];
  const year = dateObj.getFullYear()
  let month = dateObj.getMonth() + 1
  let day = dateObj.getDate()

  if (month < 10)
    month = "0" + month

  if (day < 10)
    day = "0" + day
  return year + "-" + month + "-" + day
}

export const ValidateInputs = function (event) {
  const value = event.target.value;
  if (value < 0) {
    alert("Invalid input value");
    event.target.value = '';
  }
}

export const getClientId = function () {
  const clientId = window.sessionStorage.getItem("ccode");
  return clientId
}

export const requestAPI = function (url, reqMethod, reqData, contentType) {
  return new Promise((resolve, reject) => {
    if (contentType == '')
      contentType = 'application/json';
    const token = window.sessionStorage.getItem('token')
    const customConfig = {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        'Content-Type': contentType,
        'Authorization': 'Bearer ' + token
      }
    };

    if (reqMethod === 'POST') {
      axios.post(url, reqData, customConfig).then((response) => {
        resolve(response)
      })
        .catch(err => {
          console.log(err)
          console.log(err.code)
          console.log(err.message)
          errorTracker(url,'INSERT', err)
          if (err.code === "ERR_BAD_RESPONSE" && err.message === "Request failed with status code 500") {
            logoutUser()
          } else {
            reject(err)
          }
        })
    } else if (reqMethod === 'PUT') {
      axios.put(url, reqData, customConfig).then((response) => {
        resolve(response)
      })
        .catch(err => {
          console.log(err)
          console.log(err.code)
          console.log(err.message)
          errorTracker(url,'UPDATE', err)
          if (err.code === "ERR_BAD_RESPONSE" && err.message === "Request failed with status code 500") {
            logoutUser()
          } else {
            reject(err)
          }
        }) 
    } else if (reqMethod === 'DELETE') {
      axios.delete(url, {...customConfig,data: reqData}).then((response) => {
        resolve(response)
      })
        .catch(err => {
          console.log(err)
          console.log(err.code)
          console.log(err.message)
          errorTracker(url,'DELETE', err)
          if (err.code === "ERR_BAD_RESPONSE" && err.message === "Request failed with status code 500") {
            // logoutUser()
          } else {
            reject(err)
          }
        })
    } 
    else {
      axios.get(url, customConfig).then((response) => {
        resolve(response)
      })
        .catch(err => {
          console.log(err)
          console.log(err.code)
          console.log(err.message)
          errorTracker(url,'GET', err)
          if (err.code === "ERR_BAD_RESPONSE" && err.message === "Request failed with status code 500") {
            logoutUser()
          } else {
            reject(err)
          }
        })
    }
  })
}

const logoutUser = function () {
  alert("Your session has been expired. Please login again")
  const client = window.sessionStorage.getItem("client");
  sessionStorage.clear();
  window.location.href = LOGOUT_LINK
}

export const validateUser = function (params) {
  let client = window.sessionStorage.getItem("client");
  if (client == null || client == undefined || client == "") {
    client = params.get('c');
    if (client == null || client == undefined || client == "") {
      window.location.href = LOGOUT_LINK
    } else {
      window.sessionStorage.setItem("client", client);
      window.sessionStorage.setItem("ccode", params.get('ccode'));
      window.sessionStorage.setItem("level", "ADMIN");
    }
  }
  if (client !== 'demo') {
    let token = window.sessionStorage.getItem("token");
    if (token == null || token == undefined || token == "") {
      token = params.get('t');
      if (token == null || token == undefined || token == "") {
        window.location.href = LOGOUT_LINK
      } else {
        window.sessionStorage.setItem("token", token);
      }
    }
  } else if (client === 'demo') {
    generateDummyToken(params)
  }
}

export const generateDummyToken = function (params) {
  const token = params.get('t');
  if (token !== null && token !== undefined && token !== "") {
    window.sessionStorage.setItem("token", token);
    return
  }

  const reqData = JSON.stringify({
    username: 'demo',
    password: 'inventory1234',
  })
  console.log(reqData)

  requestAPI(TOKEN_API, 'POST', reqData, '').then(resp => {
    console.log(resp);
    const statusCode = resp.status;
    if (statusCode == 200) {
      const token = resp.data.token
      console.log(token);
      window.sessionStorage.setItem("token", token);
      window.sessionStorage.setItem("ccode", "0");
      window.sessionStorage.setItem("level", "ADMIN");
    }
  })
    .catch(err => console.log(err.response))
}


//  User Activity Tracker function 
export const userActivityTracker = async (params, menu, tab, table, location, url) => {

  try {
    
    // create object response body
    const object = {};
    Object.keys(params).map((key, index) => {
      let value = params[key];
      object[`param${index + 1}`] = key;
      object[`param${index + 1}Value`] = value;
    })

    // handling undefined params here 
    const diff = 4 - Object.keys(params).length
    if (diff > 0) {
      for (let i = Object.keys(params).length; i < 4; i++) {
        object[`param${i + 1}`] = null;
        object[`param${i + 1}Value`] = null;
      }
    }

    const reqData = JSON.stringify({ ...object, menu, tab, table, location, url });

    const response = await requestAPI(USER_TRACKER + "/" + getClientId(), 'POST', reqData, '');

  } catch (error) {
    console.log("User Tracking Failed:", error);
  }
};
export const errorTracker = async (url, reqMethod, error) => {
  try {
    const reqData = { url, reqMethod, error: error.toString() };
    const response = await axios.post(`${ERROR_TRACKER}/${getClientId()}`, reqData);
  } catch (err) {
    console.log("Error Tracking Failed:", err);
  }
}
export function formatNumberWithComma(num, currency) {
  // Convert the number to a string
  let numStr = num.toString();

  // Extract the last 3 digits first (hundreds, tens, units)
  let lastThree = numStr.substring(numStr.length - 3);
  
  // Remove the last 3 digits from the original number
  let rest = numStr.substring(0, numStr.length - 3);
  
  // Insert commas every two digits in the remaining part
  if (rest !== '') {
      rest = rest.replace(/\B(?=(\d{2})+(?!\d))/g, ",");
  }
  
  // Combine the processed parts and return
  return currency + "" +rest + (rest ? ',' : '') + lastThree;
}

/****************************************Convert Function For Prime React Tree table ****************************************/

// Function to get distinct values for a parent nodes( e.g locations, category, pattern)
const getDistinctParentNodes = (rows, key) => {
  return Array.from(new Set(rows.map((row) => row[key])));
};

//RETRUN the value for the given key e.g percetage, month1, month2 after
// finding a row based on filters(e.g. (location===loc, cat===null, patten===null))  
const findRowOfTreeNode = (rows, filter, key) => {
  const ans = rows.filter((row) => {
    return Object.keys(filter).every((filterKey) => row[filterKey] === filter[filterKey]);
  });
  return ans.length > 0 ? ans[0][key] : null;
};

// Recursive function to process tree levels based on configuration
const processLevelOfTreeNode = (rows, levels, config, filter = {}, levelIndex = 0,parent=[]) => {
  const currentKey = levels[levelIndex];
  const tree = [];
  const backgroundColor = config?.backgroundColor[levelIndex] || 'transparent';

  // Get distinct values for the current level (e.g. location)
  const distinctValues = getDistinctParentNodes(rows, currentKey);

  distinctValues.forEach((val) => {
    if (val === null) return;

    // Filter rows for the current key value
    const filteredRows = rows.filter((row) => row[currentKey] === val);


    // Prepare the data for the current node
    // console.log(val,"=>",parent)
    let node = {
      key: val,
      data: {
        name: val,
        backgroundColor: backgroundColor,
        parent:[...parent]
      },
      children: [],
      leaf: null, // New 'leaf' property to store the single child (e.g floral,dotted..)
    };

    // Get columns (copyKeys) for this level from the config
    const { copyKeys } = config.levelConfig[levelIndex];

    const extraFilter = {};
    for(let i = levelIndex+1; i < levels.length; i++){
      extraFilter[levels[i]] = null;
    }
    //getting the value of single column on the basis ofb filters (e.g. currentDate1 ki value)
    copyKeys.forEach((copyKey) => {
      node.data[copyKey] = findRowOfTreeNode(filteredRows, { ...filter, [currentKey]: val, ...extraFilter }, copyKey);
    });
    // If there are more levels, recursively process the next level (e.g. location>category>patterns)
    if (levels.length > levelIndex + 1) {
      const nextLevelFilter = { ...filter, [currentKey]: val };
      parent.push(val)
      node.children = processLevelOfTreeNode(filteredRows, levels, config, nextLevelFilter, levelIndex + 1, [...parent]);
      parent.pop();  
    }

    // If there's only one child, set it as the leaf and clear children
    if (node.children.length === 1) {
      const singleChild = node.children[0];
      node.leaf = singleChild;   // Assign single child to 'leaf'
      node.children = [];        // Empty the children array
    }

    tree.push(node);
  });

  return tree;
};

// Generic function to convert rows into the hierarchical tree structure
export const convertToTreeNode = (rows, config) => {
  return processLevelOfTreeNode(rows, config.levelKeys, config);
};
