import { createSlice } from '@reduxjs/toolkit';
import { requestAPI, getClientId } from 'pages/common/Utils';
import {
    INVENTORY_HEALTH_SUMMARY_API,
    EXCESS_INVENTORY_API,
    GET_LOCATION_DETAILS,
    API_LIMIT,
    DATA_FETCH_MSG
} from 'pages/common/Constants';
import { dispatch } from 'store/index';
import { CardMedia } from '../../../../node_modules/@mui/material/index';

/**
 * @module inventorySlice
 * 
 * This module manages the state related to inventory data using Redux Toolkit's `createSlice`.
 * It handles actions and reducers for fetching and storing inventory information such as location details,
 * current inventory data, and excess inventory data. It also manages loading states and error handling
 * for asynchronous operations related to inventory management.
 */

/**
 * @typedef {Object} InventoryState
 * @property {Array} tableData - Data for current inventory.
 * @property {Array} tableData2 - Data for excess inventory.
 * @property {string} location - Selected location for current inventory.
 * @property {string} location2 - Selected location for excess inventory.
 * @property {Array} locationData - List of available locations.
 * @property {Array} columnData - Column configuration for current inventory table.
 * @property {Array} columnData2 - Column configuration for excess inventory table.
 * @property {boolean} loading - Loading state for current inventory data.
 * @property {boolean} loading2 - Loading state for excess inventory data.
 * @property {boolean} isLoading - Overall loading state for initial data fetch.
 * @property {string|null} error - Error message, if any.
 * @property {boolean} openAlert - State to control visibility of alert messages.
 * @property {string} alertMessage - Message to be displayed in alerts.
 * @property {string} severity - Severity level for alerts ('success', 'error', 'warning', 'info').
 * @property {boolean} goBtn - State to control the 'Go' button for current inventory.
 * @property {boolean} goBtn2 - State to control the 'Go' button for excess inventory.
 * @property {boolean} btn - General state to control button availability.
 */


const initialState = {
    tableData: [],
    tableData2: [],
    location: '',
    location2: '',
    locationData: [],
    columnData: [],
    columnData2: [],
    loading: false,
    loading2: false,
    isLoading: true,
    error: null,
    openAlert: false,
    alertMessage: '',
    severity: 'success',
    goBtn: false,
    goBtn2: false,
    btn: true,
    isTableData1Fetched: false,
    isTableData2Fetched: false,
};

const inventorySlice = createSlice({
    name: 'inventory',
    initialState,
    reducers: {

        setLocationData(state, action) {
            state.locationData = action.payload;
        },
        setLocation(state, action) {
            state.location = action.payload;
        },
        setLocation2(state, action) {
            state.location2 = action.payload;
        },
        setTableData(state, action) {
            state.tableData = action.payload;
        },
        setTableData2(state, action) {
            state.tableData2 = action.payload;
        },
        setColumnData(state, action) {
            state.columnData = action.payload;
        },
        setColumnData2(state, action) {
            state.columnData2 = action.payload;
        },
        setLoading(state, action) {
            state.loading = action.payload;
        },
        setLoading2(state, action) {
            state.loading2 = action.payload;
        },
        setIsLoading(state, action) {
            state.isLoading = action.payload;
        },
        setError(state, action) {
            state.error = action.payload;
        },
        clearError(state) {
            state.error = null;
        },
        setOpenAlert(state, action) {
            state.openAlert = action.payload.open;
            state.alertMessage = action.payload.message;
            state.severity = action.payload.severity;
        },
        setGoBtn(state, action) {
            state.goBtn = action.payload;
        },
        setGoBtn2(state, action) {
            state.goBtn2 = action.payload;
        },
        setBtn(state, action) {
            state.btn = action.payload;
        },
        setIsTableData1Fetched(state, action) {
            state.isTableData1Fetched = action.payload;
        },
        setIsTableData2Fetched(state, action) {
            state.isTableData2Fetched = action.payload;
        },
    },
});

export const {
    btn, setBtn,
    tableData, setTableData,
    tableData2, setTableData2,
    clearError, setClearError,
    goBtn, setGoBtn,
    goBtn2, setGoBtn2,
    loading, setLoading,
    loading2, setLoading2,
    location, setLocation,
    location2, setLocation2,
    setAlertMessage, setSeverity,
    setColumnData, setColumnData2,
    setError, setOpenAlert,
    setIsLoading, setLocationData,
    isTableData1Fetched, setIsTableData1Fetched,
    isTableData2Fetched, setIsTableData2Fetched
} = inventorySlice.actions;

export default inventorySlice.reducer;



/**
 * Fetches location details for inventory and sets default locations.
 *
 * @function fetchLocationDetails
 * @returns {Function} Thunk action that dispatches location data and default locations.
 */

export const fetchLocationDetails = (locationDataAll) => async (dispatch) => {
    dispatch(setIsLoading(true));
    try {
        const locationValue = locationDataAll.find((x) => x.attribute === "All")?.attribute || locationDataAll[0]?.attribute ;
        dispatch(setLocationData([...locationDataAll]));
        dispatch(setLocation(locationValue));
        dispatch(setLocation2(locationValue));

    } catch (error) {
        dispatch(setError(error.toString()));
    } finally {
        dispatch(setIsLoading(false));
    }
};

/**
 * Fetches current inventory data based on the selected location.
 *
 * @function fetchData
 * @param {string} locationValue - The selected location for fetching inventory data.
 * @returns {Function} Thunk action that dispatches inventory data and updates loading states.
 */

export const fetchData = (locationValue) => async (dispatch) => {
    dispatch(setLoading(true));
    dispatch(setGoBtn(false));
    try {
        const resp = await requestAPI(
            `${INVENTORY_HEALTH_SUMMARY_API}/${getClientId()}?loc=${locationValue}`,
            "GET",
            "",
            ""
        );
        dispatch(setTableData(resp.data));
        populateColumns(resp.data)
        dispatch(setGoBtn(true));
        dispatch(setIsTableData1Fetched(true));
    } catch (error) {
        dispatch(setError(error.toString()));
    } finally {
        dispatch(setLoading(false));
    }
};

/**
 * Fetches excess inventory data based on the selected location.
 *
 * @function fetchData2
 * @param {string} locationValue - The selected location for fetching excess inventory data.
 * @returns {Function} Thunk action that dispatches excess inventory data and updates loading states.
 */
export const fetchData2 = (locationValue) => async (dispatch) => {
    dispatch(setLoading2(true));
    dispatch(setGoBtn2(false));
    dispatch(setBtn(false));
    try {
        // Fetch limited excess inventory data
        const resp1 = await requestAPI(
            `${EXCESS_INVENTORY_API}/${getClientId()}?loc=${locationValue}&limit=${API_LIMIT}`,
            "GET",
            "",
            ""
        );
        dispatch(setTableData2(resp1.data));
        populateColumns2(resp1.data)
        
        // Fetch complete excess inventory data
        const resp2 = await requestAPI(
            `${EXCESS_INVENTORY_API}/${getClientId()}?loc=${locationValue}`,
            "GET",
            "",
            ""
        );

        dispatch(setTableData2(resp2.data));
        populateColumns2(resp2.data)
        dispatch(setGoBtn2(true));
        dispatch(setBtn(true));
        dispatch(setOpenAlert({
            open: true,
            message: DATA_FETCH_MSG,
            severity: "success"
        }));
        dispatch(setIsTableData2Fetched(true));

    } catch (error) {
        dispatch(setError(error.toString()));
    } finally {
        dispatch(setLoading2(false));
    }
};


/**
 * Generates column configuration for current inventory table based on fetched data.
 *
 * @function populateColumns
 * @param {Array} tableData - The fetched current inventory data.
 * @param {Function} dispatch - The Redux dispatch function.
 */

const populateColumns = (tableData) => {
    const columns = tableData.reduce((arr, x) => {
        if (x.id > 1) return arr;

        const item = {};
        for (const key in x) {
            const value = x[key];
            if (!isNaN(value)) {
                item[key] = "sum";
            }
            if (key === "id" || key === "customer_cd" || key === "report_date") {
                continue;
            }
            arr.push({
                field: key,
                type: !isNaN(value) ? "number" : "string",
                headerName: key,
                width: 180,
                headerClassName: "super-app-theme--header",
                headerAlign: "center",
                align: "center",
            });
        }
        return arr;
    }, []);
    dispatch(setColumnData(columns));
};


/**
 * Generates column configuration for excess inventory table based on fetched data.
 *
 * @function populateColumns2
 * @param {Array} tableData2 - The fetched excess inventory data.
 * @param {Function} dispatch - The Redux dispatch function.
 */

const populateColumns2 = (tableData2) => {
    const imageColumn = {
        field: "Image",
        headerName: "Image",
        width: 160,
        headerClassName: "super-app-theme--header",
        headerAlign: "center",
        align: "center",
        renderCell: ({ row }) => (
            <CardMedia
                component="img"
                image={row.Image}
                style={{ borderRadius: "10%" }}
            />
        ),
    };

    const columns = tableData2.reduce((arr, x) => {
        if (x.id > 1) return arr;

        const item = {};
        for (const key in x) {
            const value = x[key];
            if (!isNaN(value)) {
                item[key] = "sum";
            }
            if (key === "id" || key === "customer_cd") {
                continue;
            }
            if (key === "Image") arr.push(imageColumn);
            else if (key.endsWith("ID") || key.endsWith("Id") || key.endsWith("id"))
                arr.push({
                    field: key,
                    type: "string",
                    headerName: key,
                    width: 160,
                    headerClassName: "super-app-theme--header",
                    headerAlign: "center",
                    align: "center",
                });
            else
                arr.push({
                    field: key,
                    type: !isNaN(value) ? "number" : "string",
                    headerName: key,
                    width: 160,
                    headerClassName: "super-app-theme--header",
                    headerAlign: "center",
                    align: "center",
                });
        }
        return arr;
    }, []);
    dispatch(setColumnData2(columns));
};
