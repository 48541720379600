// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MuiDataGrid-cell {
  display: flex;
  align-items: center;
}

/* Apply the border to all column headers to their left side */
.MuiDataGrid-withBorderColor .MuiDataGrid-columnHeader {
border-left: 1px solid lightgray;
}
/* Hide the aggregation column header label= SUM */
.MuiDataGrid-aggregationColumnHeaderLabel {
display: none;    
}
/* Hide the aggregation column header label= SUM */
.MuiDataGrid-aggregationColumnHeaderLabel {
  display: none;    
}

svg.p-icon.p-sortable-column-icon {
  color: white; /* Change icon color */
  height: 10px; /* Change icon size (without quotes) */
}
/* neeed to add this is u r using colorfull treetable ptherwise remove this class for expander */
button.p-treetable-toggler{
  margin-left: 16px;
  visibility: visible;
  position: absolute;
  margin-top: 22px;
}
svg.p-icon.p-treetable-toggler-icon {
  color: black; /* Change icon color */
  height: 8px; /* Change icon size (without quotes) */
  margin-right: 14px;
  margin-left: 14px;

}
/* to change the divider of filterbuttons */
.css-18tq7p1-MuiButtonGroup-root .MuiButtonGroup-firstButton {
  border:none;
}


`, "",{"version":3,"sources":["webpack://./src/global.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA,8DAA8D;AAC9D;AACA,gCAAgC;AAChC;AACA,kDAAkD;AAClD;AACA,aAAa;AACb;AACA,kDAAkD;AAClD;EACE,aAAa;AACf;;AAEA;EACE,YAAY,EAAE,sBAAsB;EACpC,YAAY,EAAE,sCAAsC;AACtD;AACA,gGAAgG;AAChG;EACE,iBAAiB;EACjB,mBAAmB;EACnB,kBAAkB;EAClB,gBAAgB;AAClB;AACA;EACE,YAAY,EAAE,sBAAsB;EACpC,WAAW,EAAE,sCAAsC;EACnD,kBAAkB;EAClB,iBAAiB;;AAEnB;AACA,2CAA2C;AAC3C;EACE,WAAW;AACb","sourcesContent":[".MuiDataGrid-cell {\n  display: flex;\n  align-items: center;\n}\n\n/* Apply the border to all column headers to their left side */\n.MuiDataGrid-withBorderColor .MuiDataGrid-columnHeader {\nborder-left: 1px solid lightgray;\n}\n/* Hide the aggregation column header label= SUM */\n.MuiDataGrid-aggregationColumnHeaderLabel {\ndisplay: none;    \n}\n/* Hide the aggregation column header label= SUM */\n.MuiDataGrid-aggregationColumnHeaderLabel {\n  display: none;    \n}\n\nsvg.p-icon.p-sortable-column-icon {\n  color: white; /* Change icon color */\n  height: 10px; /* Change icon size (without quotes) */\n}\n/* neeed to add this is u r using colorfull treetable ptherwise remove this class for expander */\nbutton.p-treetable-toggler{\n  margin-left: 16px;\n  visibility: visible;\n  position: absolute;\n  margin-top: 22px;\n}\nsvg.p-icon.p-treetable-toggler-icon {\n  color: black; /* Change icon color */\n  height: 8px; /* Change icon size (without quotes) */\n  margin-right: 14px;\n  margin-left: 14px;\n\n}\n/* to change the divider of filterbuttons */\n.css-18tq7p1-MuiButtonGroup-root .MuiButtonGroup-firstButton {\n  border:none;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
