// material-ui
import { Box, IconButton, Link,Tooltip, useMediaQuery } from '@mui/material';
import { GithubOutlined } from '@ant-design/icons';

// project import
import Search from './Search';
import Profile from './Profile';
import Notification from './Notification';
import MobileSection from './MobileSection';

import navigation from 'menu-items';
import Breadcrumbs from 'components/@extended/Breadcrumbs';
import HelpAndSupport from './HelpAndSupport';
import { useEffect, useState } from 'react';

// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent = () => {
  const matchesXs = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const [showTooltip, setShowTooltip] = useState(false);
  
  useEffect(() => {
    if (!window.sessionStorage.getItem('tooltipShown')) {
      const delayTimer = setTimeout(() => {
        setShowTooltip(true);
        const timer = setTimeout(() => {
          setShowTooltip(false);
          window.sessionStorage.setItem('tooltipShown', 'true');
        }, 3000);
        return () => clearTimeout(timer);
      }, 1500);
      return () => clearTimeout(delayTimer);
    }
  }, []);
  

  return (
    <>
      
      {<Breadcrumbs navigation={navigation} title />}
      
      <IconButton
        component={Link}
        href="https://github.com/codedthemes/mantis-free-react-admin-template"
        target="_blank"
        disableRipple
        color="secondary"
        title="Download Free Version"
        sx={{ color: 'text.primary', bgcolor: 'grey.100', display:'none' }}
      >
        <GithubOutlined />
      </IconButton>

      <Notification />
      {!matchesXs && <Profile />}
      {matchesXs && <MobileSection />}
      <Tooltip
        title="Click here for definitions and help videos👆"
        open={showTooltip}
        placement="bottom"
        arrow
        componentsProps={{
            tooltip: {
              sx: {
                backgroundColor: '#312ECB',
                padding: '12px',
                color: 'white', // Ensure text is visible
                marginRight: '24px',
                animation: 'bounce 1s ease-in infinite',
                '@keyframes bounce': {
                  '0%, 20%, 50%, 80%, 100%': { transform: 'translateY(0)' },
                  '40%': { transform: 'translateY(-10px)' },
                },
              },
            },
            arrow: {
              sx: {
                color: '#312ECB', // Match arrow color to the tooltip background
                marginLeft: '12px', // Set margin left to 28px
              },
            },
        }}
      >
        <Box>
          <HelpAndSupport />
        </Box>
      </Tooltip>
    </>
  );
};

export default HeaderContent;
