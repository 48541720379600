import { createSlice } from '@reduxjs/toolkit';
import { requestAPI, getClientId } from 'pages/common/Utils';
import { DASHBOARD_SALES_UNIT_VALUES_API, GET_INSTOCK_PERCENTAGE, GET_LOCATION_DETAILS, SKU_PERFORMANCE_DAILY_TRENDS_API } from "pages/common/Constants";


/*
 * Initial state for the instockPercentage slice.
 * 
 * - `tableData`: Stores the data fetched from the API.
 * - `loading`: Indicates whether the data is currently being loaded.
 * - `isLoading`: Another loading indicator that might be used to manage 
 *                different loading states in the UI.
 * - `error`: Holds any error messages encountered during the data fetch process.
 */

const initialState = {
    tableData: [],
    loading: true,
    isLoading: true,
    error: '',
    currencySymbol: '$',
    rows: [],
    locationData: [],
    location: '',
    loading2: true,
};
const instockPercentageSlice = createSlice({
    name: 'instockPercentage',
    initialState,
    reducers: {
        setTableData(state, action) {
            state.tableData = action.payload;
        },
        setLoading(state, action) {
            state.loading = action.payload;
        },
        setIsLoading(state, action) {
            state.isLoading = action.payload;
        },
        setCurrencySymbol(state, action) {
            state.currencySymbol = action.payload;
        },
        setError(state, action) {
            state.error = action.payload;
        },

        setRows(state, action) {
            state.rows = action.payload;
        },
        setLoading2(state, action) {
            state.loading2 = action.payload;
        },
        setLocationData(state, action) {
            state.locationData = action.payload;
        },
        setLocation(state, action) {
            state.location = action.payload;
        },
    },
});

/*
 * Exporting the actions created by the slice.
 * These actions will be used to update the state in response to user interactions
 * or API calls.
 */
export const {
    tableData, setTableData,
    setLoading,
    isLoading, setIsLoading,
    currencySymbol,setCurrencySymbol,
    setError,

    setRows,
    setLoading2,
    setLocationData,
    location, setLocation,
} = instockPercentageSlice.actions;

/*
 * Exporting the reducer to be used in the Redux store.
 * This will handle the state transitions based on the actions dispatched.
 */

export default instockPercentageSlice.reducer;


export const fetchSailsUnits = () => async (dispatch) => {
    // Indicate the start of the data fetch
    dispatch(setLoading(true));
    dispatch(setIsLoading(true));
    try {
        // Make an API request to fetch sales units data
        const resp = await requestAPI(DASHBOARD_SALES_UNIT_VALUES_API + "/" + getClientId(), 'GET', '', '');

        // Store the fetched data in the Redux store
        dispatch(setTableData(resp.data));

    } catch (error) {

        // If an error occurs, store the error message in the Redux store
        dispatch(setError(error.toString()));
    }
};


export const fetchLocationDetails = (locationDataActive) => async (dispatch) => {
    try {
        // Make an API request to fetch sku details
        const locationValue = locationDataActive.find((x) => x.attribute === "All")?.attribute || locationDataActive[0]?.attribute ;
        dispatch(setLocationData([...locationDataActive]));
        dispatch(setLocation(locationValue));

    } catch (error) {
        console.log("Failed to fetch location details: ",error)
        dispatch(setError("Failed to fetch location details"));
    }
};

export const fetchSkuDetails = (locationValue) => async (dispatch) => {
    // Indicate the start of the data fetch
    dispatch(setLoading2(true));
    try {
        const clientName = window.sessionStorage.getItem("client")
        if(clientName === 'salty')
            locationValue = 'Salty Okhla 207'
        // Make an API request to fetch sku details
        const resp = await requestAPI(GET_INSTOCK_PERCENTAGE + '?cid=' + getClientId() + "&loc=" + locationValue, 'GET', '', '');

        // Move 'new launch' category to the zero index
        const newLaunch = resp.data.filter(item => item.category === 'New Launch');
        const otherCategories = resp.data.filter(item => item.category !== 'New Launch').sort((a, b) => a.category.localeCompare(b.category));
        const updatedData = [...newLaunch, ...otherCategories];
        // Store the fetched data in the Redux store
        dispatch(setRows(updatedData));
        dispatch(setCurrencySymbol(updatedData[0].currencySymbol));
        dispatch(setLoading2(false));

    } catch (error) {

        // If an error occurs, store the error message in the Redux store
        dispatch(setError(error.toString()));
    }
};